@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Audiowide&display=swap);
/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition-delay: 100ms;
    -webkit-transform: scale(0);
            transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    -webkit-transform: scale(1);
            transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    -webkit-transform: scale(0);
            transform: scale(0);
    /* transform: translateY(-150%); */
}

body > iframe {
    display: none !important;
}


/* Dots */
.slick-slider-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 10px;

    list-style: none;

    text-align: center;
}
.slick-slider-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-slider-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-slider-dots li button:hover,
.slick-slider-dots li button:focus
{
    outline: none;
}
.slick-slider-dots li button:hover:before,
.slick-slider-dots li button:focus:before
{
    opacity: 1;
}
.slick-slider-dots li button:before
{
    font-family: 'slick';
    font-size: 10px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-slider-dots li.slick-active button:before
{
    opacity: .75;
    color: white;
}
.masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}
.masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.masonry-grid_column > img { /* change div to reference your elements you put in <Masonry> */
  background: black;
  margin-bottom: 10px;
}

.masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: black;
  margin-bottom: 10px;
}
#color_div {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    margin: 0px;
    /* background: radial-gradient(circle, #240015 0%, #12000b 100%); */
    overflow: hidden;
}

#color_div,
#pnf_text,
#svgWrap_1,
#svgWrap_2 {
    z-index: 0 !important;
}


/* .wrap{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

#pnf_text {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 150px;
    font-size: 32px;
    text-transform: uppercase;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: block;
    color: #1a202c;
    font-weight: 300;
    font-family: Audiowide;
    text-shadow: 0px 0px 4px #1a202c;
    -webkit-animation: fadeInText 3s ease-in 3.5s forwards, flicker4 5s linear 7.5s infinite;
            animation: fadeInText 3s ease-in 3.5s forwards, flicker4 5s linear 7.5s infinite;
    min-width: 100vw;
    text-align: center;
}

#svgWrap_1,
#svgWrap_2 {
    position: absolute;
    height: auto;
    width: 600px;
    max-width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 10px;
}


/* #svgWrap_1,
#svgWrap_2,
#color_div{
  animation: hueRotate 6s ease-in-out 3s infinite;
} */

#id1_1,
#id2_1,
#id3_1 {
    stroke: #e60000;
    stroke-width: 3px;
    fill: transparent;
}

#id1_2,
#id2_2,
#id3_2 {
    stroke: #1a202c;
    stroke-width: 3px;
    fill: transparent;
}

#id3_1 {
    stroke-dasharray: 940px;
    stroke-dashoffset: -940px;
    -webkit-animation: drawLine3 2.5s ease-in-out 0s forwards, flicker3 4s linear 4s infinite;
            animation: drawLine3 2.5s ease-in-out 0s forwards, flicker3 4s linear 4s infinite;
}

#id2_1 {
    stroke-dasharray: 735px;
    stroke-dashoffset: -735px;
    -webkit-animation: drawLine2 2.5s ease-in-out 0.5s forwards, flicker2 4s linear 4.5s infinite;
            animation: drawLine2 2.5s ease-in-out 0.5s forwards, flicker2 4s linear 4.5s infinite;
}

#id1_1 {
    stroke-dasharray: 940px;
    stroke-dashoffset: -940px;
    -webkit-animation: drawLine1 2.5s ease-in-out 1s forwards, flicker1 4s linear 5s infinite;
            animation: drawLine1 2.5s ease-in-out 1s forwards, flicker1 4s linear 5s infinite;
}

@-webkit-keyframes drawLine1 {
    0% {
        stroke-dashoffset: -940px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}

@keyframes drawLine1 {
    0% {
        stroke-dashoffset: -940px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}

@-webkit-keyframes drawLine2 {
    0% {
        stroke-dashoffset: -735px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}

@keyframes drawLine2 {
    0% {
        stroke-dashoffset: -735px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}

@-webkit-keyframes drawLine3 {
    0% {
        stroke-dashoffset: -940px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}

@keyframes drawLine3 {
    0% {
        stroke-dashoffset: -940px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}

@-webkit-keyframes flicker1 {
    0% {
        stroke: #e60000;
    }
    1% {
        stroke: transparent;
    }
    3% {
        stroke: transparent;
    }
    4% {
        stroke: #e60000;
    }
    6% {
        stroke: #e60000;
    }
    7% {
        stroke: transparent;
    }
    13% {
        stroke: transparent;
    }
    14% {
        stroke: #e60000;
    }
    100% {
        stroke: #e60000;
    }
}

@keyframes flicker1 {
    0% {
        stroke: #e60000;
    }
    1% {
        stroke: transparent;
    }
    3% {
        stroke: transparent;
    }
    4% {
        stroke: #e60000;
    }
    6% {
        stroke: #e60000;
    }
    7% {
        stroke: transparent;
    }
    13% {
        stroke: transparent;
    }
    14% {
        stroke: #e60000;
    }
    100% {
        stroke: #e60000;
    }
}

@-webkit-keyframes flicker2 {
    0% {
        stroke: #e60000;
    }
    50% {
        stroke: #e60000;
    }
    51% {
        stroke: transparent;
    }
    61% {
        stroke: transparent;
    }
    62% {
        stroke: #e60000;
    }
    100% {
        stroke: #e60000;
    }
}

@keyframes flicker2 {
    0% {
        stroke: #e60000;
    }
    50% {
        stroke: #e60000;
    }
    51% {
        stroke: transparent;
    }
    61% {
        stroke: transparent;
    }
    62% {
        stroke: #e60000;
    }
    100% {
        stroke: #e60000;
    }
}

@-webkit-keyframes flicker3 {
    0% {
        stroke: #e60000;
    }
    1% {
        stroke: transparent;
    }
    10% {
        stroke: transparent;
    }
    11% {
        stroke: #e60000;
    }
    40% {
        stroke: #e60000;
    }
    41% {
        stroke: transparent;
    }
    45% {
        stroke: transparent;
    }
    46% {
        stroke: #e60000;
    }
    100% {
        stroke: #e60000;
    }
}

@keyframes flicker3 {
    0% {
        stroke: #e60000;
    }
    1% {
        stroke: transparent;
    }
    10% {
        stroke: transparent;
    }
    11% {
        stroke: #e60000;
    }
    40% {
        stroke: #e60000;
    }
    41% {
        stroke: transparent;
    }
    45% {
        stroke: transparent;
    }
    46% {
        stroke: #e60000;
    }
    100% {
        stroke: #e60000;
    }
}

@-webkit-keyframes flicker4 {
    0% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    30% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    31% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    32% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    36% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    37% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    41% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    42% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    85% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    86% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    95% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    96% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    100% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
}

@keyframes flicker4 {
    0% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    30% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    31% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    32% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    36% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    37% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    41% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    42% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    85% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    86% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    95% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    96% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    100% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
}

@-webkit-keyframes fadeInText {
    1% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    70% {
        color: #e60000;
        text-shadow: 0px 0px 14px #e60000;
    }
    100% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
}

@keyframes fadeInText {
    1% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    70% {
        color: #e60000;
        text-shadow: 0px 0px 14px #e60000;
    }
    100% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
}

@-webkit-keyframes hueRotate {
    0% {
        -webkit-filter: hue-rotate(0deg);
                filter: hue-rotate(0deg);
    }
    50% {
        -webkit-filter: hue-rotate(-120deg);
                filter: hue-rotate(-120deg);
    }
    100% {
        -webkit-filter: hue-rotate(0deg);
                filter: hue-rotate(0deg);
    }
}

@keyframes hueRotate {
    0% {
        -webkit-filter: hue-rotate(0deg);
                filter: hue-rotate(0deg);
    }
    50% {
        -webkit-filter: hue-rotate(-120deg);
                filter: hue-rotate(-120deg);
    }
    100% {
        -webkit-filter: hue-rotate(0deg);
                filter: hue-rotate(0deg);
    }
}
