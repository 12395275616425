@import url('https://fonts.googleapis.com/css?family=Audiowide&display=swap');
#color_div {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    margin: 0px;
    /* background: radial-gradient(circle, #240015 0%, #12000b 100%); */
    overflow: hidden;
}

#color_div,
#pnf_text,
#svgWrap_1,
#svgWrap_2 {
    z-index: 0 !important;
}


/* .wrap{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

#pnf_text {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 150px;
    font-size: 32px;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    display: block;
    color: #1a202c;
    font-weight: 300;
    font-family: Audiowide;
    text-shadow: 0px 0px 4px #1a202c;
    animation: fadeInText 3s ease-in 3.5s forwards, flicker4 5s linear 7.5s infinite;
    min-width: 100vw;
    text-align: center;
}

#svgWrap_1,
#svgWrap_2 {
    position: absolute;
    height: auto;
    width: 600px;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}


/* #svgWrap_1,
#svgWrap_2,
#color_div{
  animation: hueRotate 6s ease-in-out 3s infinite;
} */

#id1_1,
#id2_1,
#id3_1 {
    stroke: #e60000;
    stroke-width: 3px;
    fill: transparent;
}

#id1_2,
#id2_2,
#id3_2 {
    stroke: #1a202c;
    stroke-width: 3px;
    fill: transparent;
}

#id3_1 {
    stroke-dasharray: 940px;
    stroke-dashoffset: -940px;
    animation: drawLine3 2.5s ease-in-out 0s forwards, flicker3 4s linear 4s infinite;
}

#id2_1 {
    stroke-dasharray: 735px;
    stroke-dashoffset: -735px;
    animation: drawLine2 2.5s ease-in-out 0.5s forwards, flicker2 4s linear 4.5s infinite;
}

#id1_1 {
    stroke-dasharray: 940px;
    stroke-dashoffset: -940px;
    animation: drawLine1 2.5s ease-in-out 1s forwards, flicker1 4s linear 5s infinite;
}

@keyframes drawLine1 {
    0% {
        stroke-dashoffset: -940px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}

@keyframes drawLine2 {
    0% {
        stroke-dashoffset: -735px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}

@keyframes drawLine3 {
    0% {
        stroke-dashoffset: -940px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}

@keyframes flicker1 {
    0% {
        stroke: #e60000;
    }
    1% {
        stroke: transparent;
    }
    3% {
        stroke: transparent;
    }
    4% {
        stroke: #e60000;
    }
    6% {
        stroke: #e60000;
    }
    7% {
        stroke: transparent;
    }
    13% {
        stroke: transparent;
    }
    14% {
        stroke: #e60000;
    }
    100% {
        stroke: #e60000;
    }
}

@keyframes flicker2 {
    0% {
        stroke: #e60000;
    }
    50% {
        stroke: #e60000;
    }
    51% {
        stroke: transparent;
    }
    61% {
        stroke: transparent;
    }
    62% {
        stroke: #e60000;
    }
    100% {
        stroke: #e60000;
    }
}

@keyframes flicker3 {
    0% {
        stroke: #e60000;
    }
    1% {
        stroke: transparent;
    }
    10% {
        stroke: transparent;
    }
    11% {
        stroke: #e60000;
    }
    40% {
        stroke: #e60000;
    }
    41% {
        stroke: transparent;
    }
    45% {
        stroke: transparent;
    }
    46% {
        stroke: #e60000;
    }
    100% {
        stroke: #e60000;
    }
}

@keyframes flicker4 {
    0% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    30% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    31% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    32% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    36% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    37% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    41% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    42% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    85% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    86% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    95% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    96% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
    100% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
}

@keyframes fadeInText {
    1% {
        color: #1a202c;
        text-shadow: 0px 0px 4px #1a202c;
    }
    70% {
        color: #e60000;
        text-shadow: 0px 0px 14px #e60000;
    }
    100% {
        color: #e60000;
        text-shadow: 0px 0px 4px #e60000;
    }
}

@keyframes hueRotate {
    0% {
        filter: hue-rotate(0deg);
    }
    50% {
        filter: hue-rotate(-120deg);
    }
    100% {
        filter: hue-rotate(0deg);
    }
}